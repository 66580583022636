import integrationwithApi from "../apis/integrationwithApi";
import {
  configHeader,
  configHeaderwithData,
  configHeaderwithCancel,
} from "./header-configure";
import { redirect } from "./redirect";

export const getRvnsIntegrationTypes = () => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/file/integrationtype`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const integrationFileUpload = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/file/upload`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const integrationFileDelete = (id) => async (dispatch) => {
  try {
    const response = await integrationwithApi.delete(
      `/file/delete?integrationfileid=` + id,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const integrationFileLists = (integrationId) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/file/all?integrationconnectionid=` + integrationId,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const readAttributesFromXml = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/file/data/object`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To get PTC data according to Integration Id with cancel
export const fetchPTCDataByIntegrationIdWithCancel =
  (data, controllerRef) => async (dispatch) => {
    try {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();
      const response = await integrationwithApi.post(
        `/view`,
        data,
        configHeaderwithCancel(controllerRef)
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        if (e.response !== undefined && e.response !== null && e.response) {
          return e.response;
        } else {
          return e;
        }
      }
    }
  };

//To get PTC data according to Integration Id
export const fetchPTCDataByIntegrationId = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/view`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//for single external card data according to Widget and grid id
export const fetchSingleExternalCardData = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/dashboard/card`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//for single External Bar chart data according to integration ID and properties
export const fetchSingleExternalBarchartData = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/dashboard/barchart`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//for single External Bar chart data according to integration ID and properties
export const fetchSingleExternalPiechartData = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/dashboard/piechart`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const integrationConnectionsGet = (appId) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/connection/app/get?appid=` + appId,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const getConnection = (integrationId) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/connection/get?id=` + integrationId,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const saveConnection = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/save`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const updateConnection = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.put(
      `/connection/update`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const getIntegrationStatus = (integrationId) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/connection/status?id=` + integrationId,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const getIntegrationTypeConnection =
  (integrationConnectionId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/type/get?integrationconnectionid=` +
          integrationConnectionId,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const getArofinityTypeConnection = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/type/get/arofinitytypes`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const saveIntegrationTypeConnection = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/type/save`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const updateIntegrationTypeConnection = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.put(
      `/connection/type/update`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const getArofinityExternalTypesNotWithConnection =
  (appid, integrationConnectionId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/type/arofinity/externaltype/get?appid=` +
          appid +
          `&integrationconnectionid=` +
          integrationConnectionId,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const enableIntegrationConnectionType =
  (integrationConnectionId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `connection/type/get/enable?integrationconnectionid=` +
          integrationConnectionId,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const getExternalTypeAttrWithData = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/get/external/type/attribute/data`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const saveIntegrationAttributeConnection =
  (data) => async (dispatch) => {
    try {
      const response = await integrationwithApi.post(
        `/connection/attribute/save`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const integrationDelete = (id) => async (dispatch) => {
  try {
    const response = await integrationwithApi.delete(
      `/connection/delete?id=` + id,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//For getting attributes by type Id according to new integration concepet
export const fetchExternalTypeAttributeByTypeId =
  (typeId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/attribute/arofinity/mappeddata?typeid=${typeId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };
//For getting codebeamer project lists
export const fetchCodebeamerProjects =
  (integrationConnectionId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/get/external/project/data?id=${integrationConnectionId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };
//To fetch list of only complete Integrations
export const fetchCompleteIntegrationList = (appId) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/connection/app/get/complete?appid=${appId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchExternalApiList = (integrationId) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/connection/get/external/api?id=${integrationId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchAttributesDataList =
  (integrationConnectionTypeId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/attribute/get?integrationConnectionTypeId=${integrationConnectionTypeId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const saveAttributesDataList = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/attribute/save`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchIntRelnList = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/relationship/get/data`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchExtRelnList = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/get/external/relationship/data`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const saveRelnMapping = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/relationship/save`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
      return e?.response;
    } else {
      return e?.response;
    }
  }
};

export const fetchRelnMapping =
  (integrationConnectionTypeId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/relationship?integrationConnectionId=${integrationConnectionTypeId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const fetchProjectContainer =
  (integrationConnectionTypeId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/project/get?integrationconnectionid=${integrationConnectionTypeId}`,

        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const fetchExternalRelationshipByTypeId =
  (typeId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/relationship/get/type/mapped/data?arofinitytypeid=${typeId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const fetchExternalRelationshipByTypeIdIsEdit =
  (typeId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/external/relationship?typeId=${typeId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const saveProjectContainer = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/project/save`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const updateProjectContainer = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.put(
      `/connection/project/update`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchContainsByTypeId = (typeId) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/connection/attribute/get/type/contains/data?arofinitytypeid=${typeId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchExternalSingleRelationShipData =
  (data) => async (dispatch) => {
    try {
      const response = await integrationwithApi.post(
        `/view/relationship/data`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const fetchExternalRelnWithInternal = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/get/external/relationship/data`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchExternalPossibleValue = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/get/external/type/attribute/picklist/data`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const checkingIntegrationMappedData = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/mapped/data/changes`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else if (e?.response?.status === 409) {
      return e.response;
    } else {
      return e.response;
    }
  }
};

export const updatingIntegrationMappedData = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.put(
      `/connection/mapped/data/changes`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else if (e?.response?.status === 409) {
      return e.response;
    } else {
      return e.response;
    }
  }
};

export const validatingCombinedAuthenticationDetails =
  (data) => async (dispatch) => {
    // checks the combination of URL, username ,password and authentication type
    try {
      const response = await integrationwithApi.post(
        `/connection/check/mapping/data`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

export const enableIntegrationConnectionContainer =
  (integrationConnectionId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `connection/project/get/enable?integrationconnectionid=` +
          integrationConnectionId,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const getExternalTypes =
  (integrationConnectionId, projectId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/get/external/type/data?id=` +
          integrationConnectionId +
          `&projectid=` +
          projectId,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const getExternalTypesConfig =
  (integrationConnectionId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/get/external/enable/type/data?id=` +
          integrationConnectionId,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To get external object data
export const fetchExternalTypeInstanceData = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/view/object`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchAttributeWithoutHistory = (id) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/connection/attribute/get/without/historyattribute?typeId=${id}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const fetchAttributeMapped = (id) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/connection/attribute/get/arofinity/mapped/attributes?typeId=${id}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To get external external history data
export const fetchExternalTypeInstanceHistoryData =
  (id, typeId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/view/external/history?externalTypeInstanceId=${id}&typeId=${typeId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const fetchCodebeamerRelationships =
  (integrationConnectionTypeId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/attribute/get/trackeritemchoicefield/attribute?integrationconnectiontypeid=${integrationConnectionTypeId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const fetchRelnEnabledList =
  (integrationConnectionid) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/relationship/get/enabled?integrationConnectionid=` +
          integrationConnectionid,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const fetchBckRelnByTypeId = (typeid) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `connection/relationship/get/arofinity/data?typeid=` + typeid,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const integrationTypeUses = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/type/uses`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const integrationAttributeUses = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/attribute/uses`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};
export const integrationRelationshipUses = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/relationship/uses`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};
//To get external relationship data according to object Id
export const fetchExternalRelationshipdataByObjectId =
  (data) => async (dispatch) => {
    try {
      const response = await integrationwithApi.post(
        `/view/relationship/data`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To get rv&s project list
export const getIntegrationRvnsProjectList =
  (integrationId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/get/external/project/data?id=` + integrationId,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To get all mapped project list
export const getAllIntegrationRvnsProjectMapping =
  (integrationconnectionid) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/project/get?integrationconnectionid=` +
          integrationconnectionid,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To get enabled mapped project list
export const getEnabledIntegrationRvnsProjectMapping =
  (integrationconnectionid) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/project/get/enable?integrationconnectionid=` +
          integrationconnectionid,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To save mapped project list
export const saveIntegrationRvnsProjectMapping = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/project/save`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

/*API to fetch option data to possibly add in relationship */
export const fetchRelationValuesExternal = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/external/relationship/values`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To get already linked relationship data according to object Id
export const fetchAlreadyLinkedExternalRelationshipdataByObjectId =
  (data) => async (dispatch) => {
    try {
      const response = await integrationwithApi.post(
        `/connection/external/relationship/data`,
        data,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

/*API to create link in codebeamer */
export const createLink = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/view/external/view`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

/*API to create link in Teamcenter */
export const createLinkInTC = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/view/external/connect/tc`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To attach integration relationship data
export const attachIntegrationRelationData = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      "/connection/external/relationship/data/attach",
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

//To detach integration relationship data
export const detachIntegrationRelationData = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.delete(
      "/connection/external/relationship/data/detach",
      configHeaderwithData(data)
    );
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const enableIntegrationConnectionTypebyProjectId =
  (integrationConnectionId, integrationconnectionprojectid) =>
  async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `connection/type/get/enable/byprojectid?integrationconnectionid=` +
          integrationConnectionId +
          `&integrationconnectionprojectid=` +
          integrationconnectionprojectid,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

export const fetchAttributeViaProjectId =
  (integrationConnectionTypeId, integrationconnectionprojectid) =>
  async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `connection/attribute/get/byprojectid?integrationConnectionTypeId=` +
          integrationConnectionTypeId +
          `&integrationconnectionprojectid=` +
          integrationconnectionprojectid,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

/* API to identify the external type is General integration or not. */

export const getIntegrationProjectName = (typeId) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/connection/product/name?arofinityExternalTypeId=` + typeId,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To fet mapped relationship of RV&S and CB
export const relationAccordingToRVSandCBIntegration =
  (typeId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/relationship/get/mapped/arofinity?typeid=${typeId}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To get organization list from ADO
export const getOrganizationList =
  (integrationConnectionId) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/get/external/organization?id=` + integrationConnectionId,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To get ADO project list
export const getIntegrationADOProjectList =
  (integrationId, organizationname) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/get/external/project/data?id=` +
          integrationId +
          `&organizationname=` +
          organizationname,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To get ADO team list
export const getIntegrationADOTeamList = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/get/external/teams`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const getExternalTypesForADO =
  (integrationConnectionId, projectId, organizationName) =>
  async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/get/external/type/data?id=` +
          integrationConnectionId +
          `&projectid=` +
          projectId +
          `&organizationname=` +
          organizationName,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };

//To get integrationEnabledTypeData
export const getIntegrationEnabledTypeData = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/type/get/mapped/arofinitytypes`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//To get integrationEnabledAttrData
export const getIntegrationEnabledAttrData =
  (integrationconnectionid, arofinitytypeid) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/attribute/external/mapped?integrationconnectionid=` +
          integrationconnectionid +
          `&arofinitytypeid=` +
          arofinitytypeid,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e?.response;
      }
    }
  };
//To get external view settings by tracker
export const externalViewSettings = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/external/relationship/by/tracker/app`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};
//To get ADO saved Relationship
export const getAdoExternalRelationship = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/get/external/relationship/data`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

//Get arofinity relationship api for ADO
export const fetchAroRelationshipData = (typeid) => async (dispatch) => {
  try {
    const response = await integrationwithApi.get(
      `/connection/relationship/get/arofinity/relationship/data?typeid=${typeid}`,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e.response;
    }
  }
};

//API to get mapped integration data
export const fetchMappedIntegrationData =
  (integrationconnectionid, arofinitytypeid) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/relationship/get/mapped/data?integrationconnectionid=${integrationconnectionid}&arofinitytypeid=${arofinitytypeid}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

//API to get test management integration data
export const fetchTestManagementData =
  (integrationconnectionid) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/relationship/test/management/data?integrationConnectionId=${integrationconnectionid}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

//API to get test management enabled  integration data
export const fetchEnabledTestManagementData =
  (integrationconnectionid) => async (dispatch) => {
    try {
      const response = await integrationwithApi.get(
        `/connection/relationship/test/management/get/enabled?integrationConnectionid=${integrationconnectionid}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      } else {
        return e.response;
      }
    }
  };

//API to save test management  integration data
export const saveTestManagement = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/relationship/test/management/save`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
      return e?.response;
    } else {
      return e?.response;
    }
  }
};

//API to clear Suspect
export const clearSuspect = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.put(
      `/connection/external/relationship/suspect/flag/clear`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
      return e?.response;
    } else {
      return e?.response;
    }
  }
};

//API to validte url
export const validateUrl = (data) => async (dispatch) => {
  try {
    const response = await integrationwithApi.post(
      `/connection/validate`,
      data,
      configHeader()
    );
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
      return e?.response;
    } else {
      return e?.response;
    }
  }
};
