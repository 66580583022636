import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import attributesReducer from "./attributesReducer";
import typeReducer from "./typeReducer";
import typeInstanceReducer from "./typeInstanceReducer";
import appsReducer from "./appsReducer";
import authReducer from "./authReducer";
import adminNavReducer from "./adminNavReducer";
import usersReducer from "./usersReducer";
import layoutReducer from "./layoutReducer";
import dashboardReducer from "./dashboardReducer";
import relationshipReducer from "./relationshipReducer";
import notificationsReducer from "./notificationsReducer";
import adminAppMenuReducer from "./adminAppMenuReducer";
import adminTypeMenuReducer from "./adminTypeMenuReducer";
import constraintsReducer from "./constraintsReducer";
import groupsReducer from "./groupsReducer";
import rolesReducer from "./rolesReducer";
import workflowReducer from "./workflowReducer";
import permissionsReducer from "./permissionsReducer";
import containerTypesReducer from "./containerTypesReducer1";
import teamRolesReducer from "./teamRolesReducer";
import emailReducer from "./emailReducer";
import generalReducer from "./generalReducer";
import profileImageReducer from "./profileImageReducer";
import appSettingsReducer from "./appSettingsReducer";
import typeSettingsReducer from "./typeSettingsReducer";
import languageReducer from "./languageReducer";
import userLanguageReducer from "./userLanguageReducer";
import userTimezoneReducer from "./userTimezoneReducer";
import TypeViewsReducer from "./TypeViewsReducer";
import containerReducer from "./containerReducer";
import ganttReducer from "./ganttReducer";
import KanbanReducer from "./KanbanReducer";
import globalConfigurationReducer from "./globalConfigurationReducer";
import sessionTimeoutReducer from "./sessionTimeoutReducer";
import integrationReducer from "./integrationReducer";
import schedularReducer from "./schedularReducer";
import unitReducer from "./unitReducer";
import importMessageReducer from "./importMessageReducer";
import NavigationDrawerReducer from "./NavigationDrawerReducer";
import NavigationDrawerAdminSettingsReducer from "./NavigationDrawerAdminSettingsReducer";
import loggingReducer from "./loggingReducer";
import serviceReducer from "./serviceReducer";
import migrationReducer from "./migrationReducer";
import importReducer from "./importReducer";
import NavigationDrawerReducerUserSide from "./NavigationDrawerReducerUserSide";
import externalProductEnableDisableReducer from "./externalProductEnableDisableReducer";

export default combineReducers({
  form: formReducer,
  profilePic: profileImageReducer,
  attributesData: attributesReducer,
  typeData: typeReducer,
  typeInstance: typeInstanceReducer,
  apps: appsReducer,
  auth: authReducer,
  adminNav: adminNavReducer,
  usersInfo: usersReducer,
  layouts: layoutReducer,
  dashboards: dashboardReducer,
  relations: relationshipReducer,
  notifications: notificationsReducer,
  adminAppNav: adminAppMenuReducer,
  adminTypeNav: adminTypeMenuReducer,
  constraints: constraintsReducer,
  groups: groupsReducer,
  roles: rolesReducer,
  workflows: workflowReducer,
  permissions: permissionsReducer,
  allowedTypesData: containerTypesReducer,
  teamRoles: teamRolesReducer,
  emailTemplates: emailReducer,
  appSettings: appSettingsReducer,
  typeSettings: typeSettingsReducer,
  globalSettings: globalConfigurationReducer,
  languageInfo: languageReducer,
  unitInfo: unitReducer,
  typeViews: TypeViewsReducer,
  allSupportedLanguages: userLanguageReducer,
  allSupportedTimezones: userTimezoneReducer,
  generalReducer: generalReducer,
  containerData: containerReducer,
  gantt: ganttReducer,
  kanban: KanbanReducer,
  sessionTimeout: sessionTimeoutReducer,
  integrationsList: integrationReducer,
  schedular: schedularReducer,
  showImportMessage: importMessageReducer,
  logging: loggingReducer,
  service: serviceReducer,
  migration: migrationReducer,
  adminDrawer: NavigationDrawerReducer,
  userDrawer: NavigationDrawerReducerUserSide,
  adminSettingsDrawer: NavigationDrawerAdminSettingsReducer,
  import: importReducer,
  externalProductEnableDisable: externalProductEnableDisableReducer,
});
