import authApi from "../apis/authApi";
import { configHeader } from "./header-configure";
import { redirect } from "./redirect";
let md5 = require("md5");

export const userLoginFetch = (userData) => async (dispatch) => {
  if (userData.password) {
    let pass = md5(userData.password);
    userData.password = pass;
  }
  try {
    const response = await authApi.post("/login", userData);
    if (!response?.data) {
      response.data = {};
    }
    if (response?.data?.token) {
      localStorage.setItem("token", response.data.token);
    }
    dispatch({ type: "USER_PROFILE", response: response.data });
    dispatch(fetchUserData());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      await dispatch({ type: "FETCH_TIMEOUT_STATUS", response: true });
      // window.location.href = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/wiscode/sessiontimeout/login`
    }

    // if (!e?.response?.data) {
    //   e.response.data = {};
    // }
    // e.response.data.message = "Bad credentionals";
    dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
    //TODO error handling
    return e.response;
  }
};

export const forgotPwd = (userData) => async (dispatch) => { 
  try {
    const response = await authApi.get(
      `/validateUser?usernameOrEmail=${userData}`
    );
    if (!response.data) {
      response.data = {};
    }

    return response;
  } catch (e) {
    if (!e.response.data) {
      e.response.data = {};
      e.response.data.message = "Bad credentionals";
    }

    if (e?.response?.status === 401) {
      await dispatch({ type: "FETCH_TIMEOUT_STATUS", response: true });
      window.location.href = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/wiscode/sessiontimeout/login`;
    }

    return e.response;
  }
};

export const PwdLinkExpiry = (token) => async (dispatch) => {
  try {
    const response = await authApi.get(
      `/isTokenExpired?resetPasswordToken=${token}`
    );
    if (!response.data) {
      response.data = {};
    }

    return response;
  } catch (e) {
    if (!e.response.data) {
      e.response.data = {};
      e.response.data.message = "Bad credentionals";
    }
    // e.response.data.message = "Bad credentionals";

    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    }

    // dispatch({ type: "DISPATCH_MESSAGE", response: e.response });
    //TODO error handling
    return e.response;
  }
};

export const resetPassword = (data) => async (dispatch) => {
  if (data.password) {
    let pass = md5(data.password);
    data.password = pass;
  }

  try {
    const response = await authApi.put("/change/passwordById", data);
    if (!response.data) {
      response.data = {};
    }

    return response;
  } catch (e) {
    if (!e.response.data) {
      e.response.data = {};
      e.response.data.message = "Bad credentionals";
    }
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    }

    return e.response;
  }
};

export const fetchUserData = () => async (dispatch) => {
  try {
    const response = await authApi.get("/user-profile", configHeader());
    if (!response.data) {
      response.data = {};
    }
    dispatch({ type: "USER_PROFILE", response: response.data });
  } catch (e) {
    if (e?.response?.status === 401) {
      localStorage.removeItem("token");
      redirect(e, dispatch);
    }
    //TODO error handling
  }
};

export const createAccount = (data) => async (dispatch) => {
  if (data.password) {
    let pass = md5(data.password);
    data.password = pass;
  }

  try {
    const response = await authApi.post("/signup", data, configHeader());
    return response;
  } catch (e) {
    if (e?.response?.status === 401) {
      redirect(e, dispatch);
    } else {
      return e?.response;
    }
  }
};

export const logout =
  (ipAddress, browserName, operationSystem) => async (dispatch) => {
    try {
      const response = await authApi.get(
        `/logout?ipAddress=${ipAddress}&browserName=${browserName}&operationSystem=${operationSystem}`,
        configHeader()
      );
      return response;
    } catch (e) {
      if (e?.response?.status === 401) {
        redirect(e, dispatch);
      }
      // TODO error handling
    }
  };

export const fetchExternalToken = (data) => async (dispatch) => {
  try {
    const response = await authApi.post(
      "/external-token?roleId=" +
        data.roleId +
        "&containerId=" +
        data.containerId +
        "&containerInstanceId=" +
        data.containerInstanceId +
        "&appId=" +
        data.appId,
      {},
      configHeader()
    );
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const externalSignup = (data) => async (dispatch) => {
  if (data.password) {
    let pass = md5(data.password);
    data.password = pass;
  }
  try {
    const response = await authApi.post("/external-signup", data);
    return response;
  } catch (e) {
    if (e?.response?.status === 409) {
      return e.response;
    }else{
      redirect(e, dispatch); 
    }
  }
};

export const listingToken = (data) => async (dispatch) => {
  try {
    const response = await authApi.get(
      `/external-token-exists?appId=${data.appId}&containerId=${data.containerId}&containerInstanceId=${data.containerInstanceId}`,
      configHeader()
    );
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const deleteTokenId = (tokenId) => async (dispatch) => {
  try {
    const response = await authApi.delete(
      `/delete-external-token?id=${tokenId}`,
      configHeader()
    );
    // const response = await containerAttributesApi.delete("", { data: data });
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};

export const checkUser = (user) => async (dispatch) => {
  try {
    const response = await authApi.get(`/checkuser?user=${user}`);
    return response;
  } catch (e) {
    // redirect(e, dispatch);
  }
};

export const fetchUsersLoginHistory = () => async (dispatch) => {
  try {
    const response = await authApi.get("/users-login-details", configHeader());
    return response;
  } catch (e) {
    redirect(e, dispatch);
  }
};
